<template>
    <div class="panel-inner-container">
        <div class="wizard-overlay">
            <div class="wizard-logo pr-0 pb-0 pr-xxl-5 pb-xxl-3">
                <logo></logo>
            </div>
        </div>
        <panel :steps="steps" :active-step="activeStep" :collapse-sidebar="collapseSidebar" class="bg-gray-300"
               @toggleSidebar="toggleSidebar"
                @changeStep="changeStep">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h4 class="mb-0">Title</h4>
                    <div class="text-secondary">
                        <fa class="mr-3" icon="info-circle" size="lg"></fa>
                        <fa class="text-light-green pointer" icon="tasks" size="lg" @click="toggleSidebar"></fa>
                    </div>
                </div>
            </template>

            <div v-if="activeStep === 0">
                <div class="font-weight-semibold font-20">
                    Profile
                </div>
                <div class="text-secondary mt-3">
                    <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                </div>

                <div class="mt-3">
                    <form ref="form">
                        <div class="form-group mb-3" v-for="(n,index) in 6" :key="index">
                            <label :for="'inputText'+ index" >Label</label>
                            <input :id="'inputText'+ index" class="form-control" placeholder="Placeholder" type="text" required>
                        </div>
                    </form>
                </div>
            </div>

            <div v-if="activeStep === 1">
                <div class="font-weight-semibold font-20">
                    Company
                </div>
                <div class="text-secondary mt-3">
                    <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                </div>

                <div class="mt-3">
                    <form ref="form">
                        <div class="form-group mb-3" v-for="(n,index) in 2" :key="index">
                            <label :for="'inputText'+ index" >Company Details</label>
                            <input :id="'inputText'+ index" class="form-control" placeholder="Placeholder" type="text" required>
                        </div>
                    </form>
                </div>
            </div>

            <div v-if="activeStep === 2">
                <div class="font-weight-semibold font-20">
                    Billing
                </div>
                <div class="text-secondary mt-3">
                    <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                </div>

                <div class="mt-3">
                    <form ref="form">
                        <div class="form-group mb-3" v-for="(n,index) in 4" :key="index">
                            <label :for="'inputText'+ index" >Billing Details</label>
                            <input :id="'inputText'+ index" class="form-control" placeholder="Placeholder" type="text" required>
                        </div>
                    </form>
                </div>
            </div>

            <div v-if="activeStep === 3">
                <div class="font-weight-semibold font-20">
                    Congratulations! You are done.
                </div>
                <div class="text-secondary mt-3">
                    <p>
                        Click finish to go back to the menu
                    </p>
                </div>
            </div>

            <div class="panel-buttons">
                <button v-if="!doneForm" class="btn btn-block btn-light-green my-4" @click.prevent="nextStep">Next</button>
                <router-link v-else to="/">
                    <button  class="btn btn-block btn-light-green my-4">Finish</button>
                </router-link>
            </div>

        </panel>
    </div>
</template>

<script>
    import Panel from "../../../src/components/Panel/Panel";
    import Logo from "../../../src/components/Logo/Logo";

    export default {
        name: "WizardNavigation",
        components: {
            Panel,
            Logo
        },
        data() {
            return {
                activeStep: 0,
                showMenu: true,
                steps: [
                    {
                        title: "Profile",
                        done: false,
                    },
                    {
                        title: "Company",
                        done: false,
                    },
                    {
                        title: "Billing",
                        done: false,
                    },
                ],
                collapseSidebar: false,
                doneForm: false
            }
        },
        methods: {
            toggleSidebar() {
                this.collapseSidebar = !this.collapseSidebar;
            },
            nextStep() {
                if(this.activeStep < this.steps.length - 1) {
                    this.doneStep(this.activeStep);
                    this.activeStep++;

                }
                else {
                    this.doneStep(this.activeStep);
                    this.activeStep++;
                    this.doneForm = true;
                }

            },
            changeStep(index) {
                if(this.steps[index].done === true || this.steps[index-1].done === true) {
                    this.activeStep = index
                }
            },
            doneStep(index) {
                this.steps[index].done = true
            }
        }
    }
</script>

<style scoped>

</style>
